import { cn } from "@/lib/utils";
import getIconFromName from "@/utils/functions/getIconfromName";
interface IconTextListItemProps {
  icon: string;
  header: string;
  boldHeader?: boolean;
  description: string;
  small?: boolean;
  className?: string;
  link?: string;
  textContainerClassName?: string;
  headerClassName?: string;
  descriptionClassName?: string;
  iconSize?: string;
}

export default function IconTextListItem({
  icon,
  header,
  boldHeader = false,
  description,
  small = false,
  className,
  link,
  textContainerClassName,
  headerClassName,
  descriptionClassName,
  iconSize = "48",
}: IconTextListItemProps) {
  return (
    <div
      className={cn("md:text-left flex-grow basis-1/3 pb-4 md:pb-0", className)}
    >
      <div className="flex items-start space-x-4">
        <div className={`w-[${iconSize}px] h-[${iconSize}px]`}>
          {getIconFromName(icon, iconSize)}
        </div>
        <div className={cn("md:pr-12", textContainerClassName)}>
          <div
            className={`text-primary mt-0 md:-md-1 ${small ? cn("text-base mb-0 leading-[1.5rem]", headerClassName) : "text-lg md:mb-2"} ${boldHeader ? "font-bold" : ""}`}
          >
            {header}
          </div>
          <span className={cn("mb-4 md:mb-0 text-sm", descriptionClassName)}>
            {link ? (
              <a className="underline cursor-pointer" href={link}>
                {description}
              </a>
            ) : (
              description
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
